<template>
  <modal name="modal-confirmation" class="modal-confirmation" ref="modal">
    <div slot="content">
      <div class="flex flex-wrap mt-20">
        <h3 class="w-full py-60px sm:px-30px lg:px-20 uppercase text-white font-semibold bg-primary">{{ $t(messageText) }}</h3>
        <div class="w-full text-center py-60px sm:px-30px lg:px-0 lg:py-0 relative mt-20">
          <button-full @click.native="confirm" class="ml-10 mr-5">
            {{ $t(confirmText) }}
          </button-full>
          <button-full @click.native="reject" class="ml-5 mr-10">
            {{ $t(rejectText) }}
          </button-full>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import ButtonFull from 'theme/components/theme/ButtonFull'
import Modal from 'theme/components/core/Modal'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'

export default {
  data () {
    return {
      isVisible: false,
      messageText: undefined,
      confirmText: undefined,
      rejectText: undefined,
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  methods: {
    open (opts = {}) {
      this.messageText = opts.messageText || 'Are you sure?',
      this.confirmText = opts.confirmText || 'OK',
      this.rejectText = opts.rejectText || 'Cancel'
      this.$refs.modal.toggle(true)
      return new Promise( (res, rej) => {
        this.resolvePromise = res
        this.rejectPromise = rej
      })
    },
    close () {
      this.isVisible = false
      this.$refs.modal.toggle(false)
    },
    confirm () {
      this.close()
      this.resolvePromise(true)
    },
    reject () {
      this.close()
      this.resolvePromise(false)
    }
  },
  components: {
    ButtonFull,
    Modal,
    ButtonOutline,
    BaseInput
  }
}
</script>

<style lang="scss">
  .modal-confirmation {
    .modal-container {
      width: 900px;

      .image {
        height: 500px;
        display: block;
        img {
          height: 100%;
        }
      }

    }
  }
</style>

import config from 'config'
import rootStore from '@vue-storefront/core/store'

export default {
  name: 'PriceRules',
  computed: {
    currentUser () {
      return this.$store.state.user.current
    },
    customerIsLogged () {
      return rootStore.getters['user/isLoggedIn']
    },
    customerGroup () {
      return this.customerIsLogged ? this.currentUser.group_id : ''
    }
  },
  methods: {
    getRulePrice (product) {
      if (product.override_point_redemption_price) {
        return product.point_redemption_price || false
      }

      if (product.tier_prices && product.tier_prices.length) {
        let tierPriceByCustomer = product.tier_prices.find(price => price.customer_group_id === this.customerGroup)

        if (!tierPriceByCustomer) return false

        return tierPriceByCustomer.extension_attributes.website_id === config.defaultWebsiteId || tierPriceByCustomer.extension_attributes.website_id === 0
          ? tierPriceByCustomer.value / tierPriceByCustomer.qty
          : false
      }

      if (!product.catalog_rule_price || !config.priceRulesEnabled) return false

      return (product.catalog_rule_price[this.customerGroup])
        ? parseFloat(product.catalog_rule_price[this.customerGroup].rule_price).toFixed(2)
        : false
    }
  }
}

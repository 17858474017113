import omit from 'lodash-es/omit'
import mapValues from 'lodash-es/mapValues'
import groupBy from 'lodash-es/groupBy'
import {mapGetters} from 'vuex'

export default {
  name: 'ProductConfigurable',
  beforeMount () {
    this.$store.dispatch('attribute/list', {
      filterValues: ['shade_group', 'shade'],
      filterField: 'attribute_code'
    })
  },
  computed: {
    ...mapGetters({
      attributesByCode: 'attribute/attributeListByCode'
    })
  },
  methods: {
    transformGroupNaming (id, displayUndefined = false, code = null) {
      switch (id) {
        case 'undefined':
        case 'null':
        case '':
        case 0:
          return displayUndefined ? 'Unassigned' : false
      }

      if (code == null) {
        return null
      }

      return ((((this.attributesByCode || {})[code] || {}).options || []).find(x => x.value === id) || {}).label || id
    },
    sortConfigurableProductsByGrouping (groupingAttributeCode, products) {
      return mapValues(groupBy(products, groupingAttributeCode), list => list.map(x => omit(x, groupingAttributeCode)))
    },
    hasShade (product) {
      return !!product.configurable_children.find(x => x.shade)
    },
    productGrouping (children, groupingAttributeCode, parentProduct = null) {
      let code = groupingAttributeCode
      let attributeShade = null

      if (parentProduct && code === 'shade_group') {
        attributeShade = (this.attributesByCode['shade'] || {}).options || []
      }

      if (code == null) {
        return [
          {
            items: children
          }
        ]
      }

      let output = []
      const attributes = (this.attributesByCode[code] || {}).options || []
      const values = mapValues(groupBy(children, code), list => list.map(x => omit(x, code)))

      for (const [key, items] of Object.entries(values)) {
        output.push({
          meta: attributes.find(x => String(x.value) === String(key)),
          key,
          items: code === 'shade_group' ? items.sort((a, b) => {
            let first = attributeShade.find(x => String(x.value) === String(a['shade']))
            let second = attributeShade.find(x => String(x.value) === String(b['shade']))

            return (first || {}).sort_order > (second || {}).sort_order ? 1 : -1
          }) : items
        })
      }

      return output.sort((a, b) => {
        return a.meta.sort_order - b.meta.sort_order
      })
    },
    getConfigurableProductGroupingsMapping (optionAttributeCode, product = null) {
      let attributeShade = null
      let groupingAttributeCode = null
      let hasShade = false

      product = product || this.product

      if (product.type_id === 'configurable') {
        hasShade = product.configurable_children.find(x => x.shade)

        if (hasShade) {
          groupingAttributeCode = 'shade_group'
          attributeShade = (this.attributesByCode['shade'] || {}).options || []
        }
      }

      let template = this.getConfigurableProductGroupings(groupingAttributeCode, product)

      let output = []

      let attribute = (this.attributesByCode[groupingAttributeCode] || {}).options || []

      for (const [key, group] of Object.entries(template)) {
        let items = group.map(product => {
          return this.options[optionAttributeCode].find(x => String(x.id) === String(product[optionAttributeCode]))
        })

        output.push({
          key,
          name: this.transformGroupNaming(key, true, groupingAttributeCode),
          items: hasShade ? items.sort((a, b) => {
            let first = attributeShade.find(x => String(x.value) === String(a['id']))
            let second = attributeShade.find(x => String(x.value) === String(b['id']))

            return (first || {}).sort_order > (second || {}).sort_order ? 1 : -1
          }) : items
        })
      }

      output = output.sort((a, b) => {
        return (attribute.find(x => String(x.value) === String(a.key)) || {}).sort_order - (attribute.find(x => String(x.value) === String(b.key)) || {}).sort_order
      })

      return output
    },
    getConfigurableProductGroupings (groupingAttributeCode, product = null) {
      return mapValues(groupBy((product || this.product).configurable_children, groupingAttributeCode), list => list.map(x => omit(x, groupingAttributeCode)))
    }
  }
}


<template>
  <div class="drawer" :class="{'open': drawerOpen}">
    <ul class="text-left list-disc p-5">
      <li class="pb-5" :key="idx" v-if="item" v-for="(_item, idx) in parseBlok(item.Instructions)">
        {{ _item }}
      </li>
    </ul>
    <button class="toggleButton" :class="{'open': drawerOpen}" id="toggleButton" @click="toggleDrawer">{{ drawerText }}</button>
  </div>
</template>
<script>
import {
  Blok
} from 'src/modules/vsf-storyblok-module/components'
export default {
  extends: Blok,
  name: 'QuickOrderInstructions',
  data () {
    return {
      drawerOpen: false
    }
  },
  computed: {
    drawerText () {
      return this.drawerOpen ? 'Close' : 'Need Help?'
    }
  },
  methods: {
    toggleDrawer () {
      this.drawerOpen = !this.drawerOpen
    },
    parseBlok (blok) {
      if (this.$isServer) return []

      if (blok.text) {
        return blok.text
      } else if (blok.content) {
        let listItems = []

        for (const item of blok.content) {
          listItems.push(this.parseBlok(item))
        }

        return (listItems.flat() || []).flat()
      }
      return []
    }
  }
}

</script>

<style scoped>
@media print {
  .drawer {
    display: none;
  }
}

.drawer {
  position: fixed;
  left: -300px; /* width of the drawer */
  top: 0;
  bottom: 0;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  z-index: 100;
  overflow: scroll;
}
.drawer.open {
  left: 0;
}
.toggleButton {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transform: rotate(-90deg);
  transform-origin: left top;
  transition: left 0.3s ease;
}
.toggleButton.open {
  left: 300px;
}

</style>

<template>
  <div class="main" id="mainContainer">
    <div class="container-override print-container lg:px-16 m-auto mt-10">
      <Transition>
        <h1 v-if="!loading">QUICK ORDER</h1>
      </Transition>
      <Transition>
        <div class="tab-container" v-if="!loading">
          <button v-for="(brand, key) in productBrands" :key="key" @click="tabClick(brand.id)"
                  class="brandTab"
                  :class="{ active: brand.id === currentBrand }"
          >{{ brand.label }}</button>
          <div v-if="!usersAllowedBrands.map(el => el.id).includes(currentBrand)" class="noPermissionCopy">
            <b>It appears you don't currently have access to this brand, for more information please contact your Sales Representative</b>
          </div>
          <div v-show="canUseAdvanceFeatures">
              <span class="mt-3 mb-0 ml-auto mr-2 cursor-pointer" @click="toggleEditOrderName">
              &#x270E;
            </span>
            <select class="button inline px-2 py-1 my-auto mx-1 border-2" style="width: 17%;"
                    v-model="selectedOrder" v-if="!isEditingOrderName">
              <option v-for="(order, idx) in orderList" :value="idx" :key="idx" >
                {{ order.name }}
              </option>
            </select>
            <input class="px-2" style="width: 17%;" v-else v-model="currentOrder.name" />
            <button class="button inline px-2 py-1 my-auto mx-1 border-2" @click="createOrder">
              New Order
            </button>
            <button class="button inline px-2 py-1 my-auto mx-1 border-2" @click="deleteOrder">
              Delete Order
            </button>
          </div>

          <div style="display: inline; margin: 5px 0 5px auto;">
            <button class="clearOrderButton" @click="handleClearOrder">Clear Order</button>
          </div>
        </div>
      </Transition>
    </div>
    <Transition>
      <article v-if="cartAddLoadingScreen" class="category loading-screen cart-loading">
        <div class="cart-loading-container">
          <h2>{{ cartLoadingMessage }}</h2>
          <loader class="inline-block" :color="`#000`" aria-label="Loading" />
        </div>
      </article>
    </Transition>
      <article v-if="loading" class="category loading-screen print-container">
        <h2>Loading Quick Order App...</h2>
        <div class="loading-container">
          <div class="loading-bar">
            <div id="loading-bar-fill"></div>
          </div>
        </div>
      </article>
    <Transition>
    <quick-order-instructions v-if="blok" :item="blok.content" />
    </Transition>
    <Transition>
    <article class="category print-container" v-if="!loading">
      <div class="container-override mx-auto lg:px-16 pb-10 lg:pb-0 grid-container" v-for="(category, key) in visibleProducts" :key="key"
           v-if="category.products && category.products.length"
      >
        <h2 class="grid-header">{{ category.name }}</h2>
        <div class="grid-1 header">Product</div>
        <div class="grid-2 header">SKU</div>
        <div class="grid-3 header">Price</div>
        <div class="grid-5 header">Qty Purchased</div>
        <div class="grid-6 header">Qty Redeemed</div>
        <template v-for="(product) in category.products">
          <template v-if="product.configurable_children && product.configurable_children.length > 0">
            <h3 style="grid-column-start: 1; grid-column-end: 3;">{{ product.name }}</h3>
            <template v-for="x in productGrouping(product.configurable_children, hasShade(product) ? 'shade_group' : null, product)">
              <div v-if="x.meta" class="grid-header mt-2 mb-4 border-b border-solid text-grey-13 border-grey-13 italic">{{x.meta.label}}</div>
            <template v-for="p in x.items">
              <div class="grid-1" :class="{ 'out-of-stock' : !p.stock.is_in_stock }">{{ getShadeName(product, p) }}</div>
              <div class="grid-2" :class="{ 'out-of-stock' : !p.stock.is_in_stock }">{{ p.sku }}</div>
              <div class="grid-3" :class="{ 'out-of-stock' : !p.stock.is_in_stock }">
                <span v-if="getRulePrice(p) !== false">${{ getRulePrice(p) }}</span>
                <span v-else>${{ p.price }}</span>
              </div>
              <div class="grid-4">
                <div v-if="stockFailSkus[p.sku]" style="color: red; margin: auto;" :title="stockFailSkus[p.sku]">&#x26A0;</div>
                <div v-if="stockPassSkus[p.sku]" style="color: green; margin: auto">&#x2713;</div>
              </div>
              <input
                class="bulkInputField grid-5 focus:outline-none focus:bg-grey-11"
                data-qty-money
                :class="{ qtyError: stockFailSkus[p.sku], qtySuccess: stockPassSkus[p.sku], permissionBlocked: !isBrandAllowed }"
                :tabindex="!isBrandAllowed ? '-1': '1'"
                :id="'money-input-'+p.sku"
                :disabled="!p.stock.is_in_stock || !isBrandAllowed"
                :value="currentOrder && currentOrder.order_data && currentOrder.order_data[p.sku] && currentOrder.order_data[p.sku].mq && currentOrder.order_data[p.sku].mq > 0 ? currentOrder.order_data[p.sku].mq : ''"
                @change="moneyInputChangeHandler(p, Number($event.target.value))"
                @focus="updateCurrentField('money-input-'+product.sku)"
              />
              <input
                v-if="p.point_can_spend"
                class="bulkInputField grid-6 focus:outline-none focus:bg-grey-11"
                data-qty-points
                :class="{ qtyError: stockFailSkus[product.sku], qtySuccess: stockPassSkus[product.sku], permissionBlocked: !isBrandAllowed }"
                :tabindex="!isBrandAllowed ? '-1': '1'"
                :id="'loyalty-input-'+p.sku"
                :disabled="!p.stock.is_in_stock"
                :value="currentOrder && currentOrder.order_data && currentOrder.order_data[p.sku] && currentOrder.order_data[p.sku].pq && currentOrder.order_data[p.sku].pq > 0 ? currentOrder.order_data[p.sku].pq : ''"
                @change="pointsInputChangeHandler(p, Number($event.target.value))"
                @focus="updateCurrentField('loyalty-input-'+product.sku)"
              />
              <div class="grid-6 text-center leading-h2" data-qty-points v-else>
                Not Eligible
              </div>
            </template>
            </template>
          </template >
          <template v-else-if="product.product_links && product.product_links.length > 1 && product.product_links.find(link => link.link_type === 'associated')">
            <h3 style="grid-column-start: 1; grid-column-end: 3;">{{ product.name }}</h3>
            <template v-for="p in product.product_links">
              <div class="grid-1" :class="{ 'out-of-stock' : !p || !p.product || !p.product.stock.is_in_stock }" v-if="p && p.product">{{ p.product.short_name }}</div>
              <div class="grid-2" :class="{ 'out-of-stock' : !p || !p.product || !p.product.stock.is_in_stock }" v-if="p && p.product">{{ p.product.sku }}</div>
              <div class="grid-3" :class="{ 'out-of-stock' : !p || !p.product || !p.product.stock.is_in_stock }" v-if="p && p.product">
                <span v-if="getRulePrice(p.product) !== false">${{ getRulePrice(p.product) }}</span>
                <span v-else>${{ p.product.price }}</span>
              </div>
              <div class="grid-4" v-if="p && p.product">
                <div v-if="stockFailSkus[product.sku]" style="color: red; margin: auto;" :title="stockFailSkus[product.sku]">&#x26A0;</div>
                <div v-if="stockPassSkus[product.sku]" style="color: green; margin: auto">&#x2713;</div>
              </div>
              <input
                v-if="p && p.product"
                class="bulkInputField grid-5 focus:outline-none focus:bg-grey-11"
                data-qty-money
                :class="{ qtyError: stockFailSkus[product.sku], qtySuccess: stockPassSkus[product.sku], permissionBlocked: !isBrandAllowed }"
                :tabindex="!isBrandAllowed ? '-1': '1'"
                :id="'money-input-'+p.product.sku"
                :disabled="!p.product.stock.is_in_stock"
                :value="currentOrder && currentOrder.order_data && currentOrder.order_data[p.product.sku] && currentOrder.order_data[p.product.sku].mq && currentOrder.order_data[p.product.sku].mq > 0 ? currentOrder.order_data[p.product.sku].mq : ''"
                @change="moneyInputChangeHandler(p.product, Number($event.target.value))"
                @focus="updateCurrentField('money-input-'+p.product.sku)"
              />
              <input
                v-if="p && p.product && product.point_can_spend"
                class="bulkInputField grid-6 focus:outline-none focus:bg-grey-11"
                data-qty-points
                :class="{ qtyError: stockFailSkus[product.sku], qtySuccess: stockPassSkus[product.sku], permissionBlocked: !isBrandAllowed }"
                :tabindex="!isBrandAllowed ? '-1': '1'"
                :id="'loyalty-input-'+p.product.sku"
                :disabled="!p.product.stock.is_in_stock"
                :value="currentOrder && currentOrder.order_data && currentOrder.order_data[p.product.sku] && currentOrder.order_data[p.product.sku].pq && currentOrder.order_data[p.product.sku].pq > 0 ? currentOrder.order_data[p.product.sku].pq : ''"
                @change="pointsInputChangeHandler(p.product, Number($event.target.value))"
                @focus="updateCurrentField('loyalty-input-'+p.product.sku)"
              />
              <div class="grid-6 text-center leading-h2" data-qty-points v-else>
                Not Eligible
              </div>
            </template>
          </template>
          <template v-else>
            <div class="grid-1" :class="{ 'out-of-stock' : !product.stock.is_in_stock }">{{ product.name }}</div>
            <div class="grid-2" :class="{ 'out-of-stock' : !product.stock.is_in_stock }">{{ product.sku }}</div>
            <div class="grid-3" :class="{ 'out-of-stock' : !product.stock.is_in_stock }" v-if="!product.configurable_children || product.configurable_children.length <= 0">${{ getRulePrice(product) }}</div>
            <div class="grid-4">
              <div v-if="stockFailSkus[product.sku]" style="color: red; margin: auto;" :title="stockFailSkus[product.sku]">&#x26A0;</div>
              <div v-if="stockPassSkus[product.sku]" style="color: green; margin: auto">&#x2713;</div>
            </div>
            <input
              class="bulkInputField grid-5 focus:outline-none focus:bg-grey-11"
              data-qty-money
              :class="{ qtyError: stockFailSkus[product.sku], qtySuccess: stockPassSkus[product.sku], permissionBlocked: !isBrandAllowed }"
              :tabindex="!isBrandAllowed ? '-1': '1'"
              :id="'money-input-'+product.sku"
              :disabled="!product.stock.is_in_stock || !isBrandAllowed"
              :value="currentOrder && currentOrder.order_data && currentOrder.order_data[product.sku] && currentOrder.order_data[product.sku].mq && currentOrder.order_data[product.sku].mq > 0 ? currentOrder.order_data[product.sku].mq : ''"
              @change="moneyInputChangeHandler(product, Number($event.target.value))"
              @focus="updateCurrentField('money-input-'+product.sku)"
            />
            <input
              v-if="product.point_can_spend"
              data-qty-points
              class="bulkInputField grid-6 focus:outline-none focus:bg-grey-11"
              :class="{ qtyError: stockFailSkus[product.sku], qtySuccess: stockPassSkus[product.sku], permissionBlocked: !isBrandAllowed }"
              :tabindex="!isBrandAllowed ? '-1': '1'"
              :id="'loyalty-input-'+product.sku"
              :disabled="!product.stock.is_in_stock || !isBrandAllowed"
              :value="currentOrder && currentOrder.order_data && currentOrder.order_data[product.sku] && currentOrder.order_data[product.sku].pq && currentOrder.order_data[product.sku].pq > 0 ? currentOrder.order_data[product.sku].pq : ''"
              @change="pointsInputChangeHandler(product, Number($event.target.value))"
              @focus="updateCurrentField('loyalty-input-'+product.sku)"
            />
            <div class="grid-6 text-center leading-h2 bulkInputField" data-qty-points v-else>
              Not Eligible
            </div>
          </template>
        </template>
      </div>
    </article>
    </Transition>
    <div v-if="!loading" class="floating-cart-summary print:hidden">
      <div v-if="useDetailedSummary" v-for="brandId in Object.keys(orderSummary)" :key="brandId">
        <span class="text">{{ usersAllowedBrands.find(b => b.brand_id == brandId).label }} Total: </span><span class="number">${{ orderSummary[brandId].toFixed(2) }}</span>
      </div>
      <div><span class="text font-bold">Purchase Total: </span><span class="number font-bold">${{ orderMoneyTotal.toFixed(2) }}</span></div>
      <div><span class="text font-bold">{{ freeShippingMessage }}</span></div>
      <hr style="margin: 5px 0;">
      <div><span class="text">Redeemed Total: </span><span class="number">{{ orderPointsTotal.toFixed(2) }}</span></div>
      <div><span class="text">Points Remaining: </span><span class="number">{{ remainingPoints.toFixed(2) }}</span></div>

      <button class="addToCart bg-primary2:hover" @click="addToRealCart">Add To Cart</button>
    </div>
    <ConfirmationPopup ref="addToCartConfirm" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import Loader from 'theme/components/theme/Loader'
import ConfirmationPopup from 'theme/components/core/ConfirmationPopup'
import i18n from '@vue-storefront/i18n'
import config from 'config'
import StoryblokSingle from 'theme/mixins/StoryblokSingle'
import QuickOrderInstructions from 'theme/components/storyblok/Blocks/QuickOrderInstructions'
import ProductConfigurable from 'theme/mixins/pages/ProductConfigurable'

export default {
  name: 'QuickOrder',
  components: {
    Loader,
    ConfirmationPopup,
    QuickOrderInstructions
  },
  mixins: [
    PriceRules,
    StoryblokSingle,
    ProductConfigurable
  ],
  data () {
    return {
      isEditingOrderName: false,
      blokSlug: 'quick-order-instructions',
      cartAddLoadingScreen: false,
      loading: true,
      orderMoneyTotal: 0,
      orderPointsTotal: 0,
      orderSummary: {},
      usersAllowedBrands: [],
      currentBrand: 41,
      currentField: 0,
      // brand_ids: 1 = R+Co, 5 = Bleu, 6 = R+Color, this is just for the query since we use categories to filter by brand
      // ids are categories, each brand's sub-categories only relate to them through these ids
      productBrands: [
        {
          id: 41,
          label: 'R+Co',
          brand_id: 1
        },
        {
          id: 139,
          label: 'R+Co Bleu',
          brand_id: 5
        },
        {
          id: 159,
          label: 'R+Color',
          brand_id: 6
        }
      ]
    }
  },
  async beforeMount () {
    // get user's allowed brands; the array->set->array thing is to remove dupes since we're mapping from customer's locations
    const brandLocations = this.currentUser ? ((this.currentUser || {}).extension_attributes || {}).locations_data : []
    const brands = Array.from(new Set(brandLocations.filter(loc => loc.status === true).map(loc => loc.brand_id)))

    this.usersAllowedBrands = []
    this.productBrands.forEach(brandObj => {
      if (brands.includes(brandObj.brand_id)) {
        this.usersAllowedBrands.push(brandObj)
        this.orderSummary[brandObj.brand_id] = 0
      }
    })
  },
  async mounted () {
    await this.$store.dispatch('quickorder/fetchProductList', this.usersAllowedBrands.map(brand => brand.brand_id))
    await this.$store.dispatch('quickorder/loadOrders')

    this.loading = false
    this.turnOffLoadingScreen()
    // add to this object: {sku: msg}
    // the skus in question will have a red-border qty box, with the msg as a warning tooltip
    this.stockPassSkus = {}
    this.stockFailSkus = {}

    // set up key events
    window.addEventListener('keyup', (ev) => {
      if (ev.key === 'Enter' && ev.shiftKey === true && !this.isEditingOrderName) {
        this.focusPrevFieldRow()
      }
      else if (ev.key === 'Enter' && !this.isEditingOrderName) {
        this.focusNextFieldRow()
      } else if (ev.key === 'Enter' && this.isEditingOrderName) {
        this.toggleEditOrderName()
      }
    })

    this.$nextTick(() => {
      if (!this.canUseAdvanceFeatures) {
        if (this.orderList.length > 0) {
          console.log(this.orderList.length, 'using order 0')
          // select first in list
          this.selectedOrder = 0
          this.updateTotals()
        } else {
          console.log('creating order')
          this.createOrder()
        }
      }

      window.setTimeout(() => {
        this.updateTotals()
      }, 500)
    })
  },
  computed: {
    ...mapGetters({
      getCustomer: ['loyalty/getCustomer'],
      getMoneyTotal: ['quickorder/getMoneyTotal'],
      getPointsTotal: ['quickorder/getPointsTotal']
    }),
    ...mapState({
      currentUser: (state) => state.user.current,
      brands: (state) => state.brands.brands,
      productList: (state) => state.quickorder.productList,
      childProducts: (state) => state.quickorder.childProducts,
      orderList: (state) => state.quickorder.orders,
      currentOrderData: (state) => state.quickorder.orders[state.quickorder.currentOrder].order_data,
      currentOrder: (state) => state.quickorder.orders[state.quickorder.currentOrder],
      freeShippingMessage: (state) => state.quickorder.freeShippingMessage
    }),
    selectedOrder: {
      get () {
        return this.$store.state.quickorder.currentOrder
      },
      set (value) {
        this.$store.dispatch('quickorder/updateCurrentOrder', value)
        this.updateTotals()
      }
    },
    canUseAdvanceFeatures () {
      return (config.quickorder || {}).advanceFeatures
    },
    // depends on this.currentBrand, which changes when clicking brand tabs
    isBrandAllowed () {
      return this.usersAllowedBrands.find( el => el.id === this.currentBrand)
    },
    useDetailedSummary () {
      return config.quickorder ? config.quickorder.useDetailedSummary : false
    },
    inputFields () {
      return document.querySelectorAll('.bulkInputField')
    },
    remainingPoints () {
      let prebalance = ((((this.getCustomer || {}).accounts || []).find(acc => acc.bank.code === 'rco') || {}).balance || 0) / 100
      return (prebalance - this.orderPointsTotal)
    },
    visibleProducts () {
      let out = []

      Object.values(this.currentCategories).forEach(cat => {
        if (cat.parent_id === this.currentBrand) {
          let category = {}

          category.id = cat.id
          category.position = cat.position
          category.name = cat.name
          category.products = []
          // can't use filter by category_ids, because configurable children don't have that field
          Object.values(this.productList).forEach(product => {
            if (product.category_ids && product.category_ids.includes(cat.id)) { category.products.push(product) }
          })

          category.products.sort((a, b) => {
            // sort by position in category
            let first = a.category.find(x => x.category_id === cat.id)
            let second = b.category.find(x => x.category_id === cat.id)

            return first.position - second.position
          })

          out.push(category)
        }
      })
      // sort categories by position after populating
      out.sort((a, b) => a.position > b.position ? 1 : -1)
      return out
    },
    currentCategories () {
      let out = {}

      this.$store.getters['category/list'].forEach(cat => {
        if (cat.parent_id === this.currentBrand) {
          out[cat.id] = cat
        }
      })
      return out
    }
  },
  methods: {
    createOrder () {
      this.$store.dispatch('quickorder/createOrder')
    },
    deleteOrder () {
      this.$store.dispatch('quickorder/deleteOrder')
    },
    toggleEditOrderName () {
      this.isEditingOrderName = !this.isEditingOrderName
      if (!this.isEditingOrderName) {
        this.saveOrder()
      }
    },
    async handleClearOrder() {
      let confirmClear = false
      confirmClear = await this.$refs.addToCartConfirm.open({
        messageText: 'This will clear your current selections. Continue?',
        confirmText: 'OK',
        rejectText: 'Cancel'
      })
      if (confirmClear) {
        this.$store.dispatch('quickorder/clearOrder')
        this.updateTotals()
      }
    },
    saveOrder () {
      this.$store.dispatch('quickorder/saveOrder')
    },
    setSuccessAttribute () {
      document.getElementById("mainContainer").dataset.addToCartSuccess = true
    },
    updateCurrentField (id) {
      this.currentField = Array.from(this.inputFields).findIndex(el => el.id === id)
    },
    // keyboard input field traversal
    // FIXME bugged, the +2/-2 move depends on there being both money and points input boxes, which isn't always true
    //       probably need to redo the input field id scheme/how they're collected into this.inputFields for traversing
    focusPrevFieldRow () {
      // top row
      if (this.currentField - 2 < 0) { return }

      // prev row active, move and return
      if (this.inputFields[this.currentField - 2].disabled === false) {
        this.currentField -= 2
        this.inputFields[this.currentField].focus()
        return
      }

      // prev row disabled or permission blocked, loop until active row or top reached.
      // if top reached without finding active row, return without changing currentField
      let tmp = this.currentField - 2
      if (this.inputFields[tmp] && this.inputFields[tmp].disabled === true) {
        while (this.inputFields[tmp] && this.inputFields[tmp].disabled === true) {
          tmp -= 2
          if (!this.inputFields[tmp]) { return } // couldn't find active row before top of list, return without changing currentField
          else if (this.inputFields[tmp] && this.inputFields[tmp].disabled === false) { this.currentField = tmp; break }
        }
      }
      this.inputFields[this.currentField].focus()
    },
    focusNextFieldRow () {
      // bottom row
      if (this.currentField + 2 >= this.inputFields.length) { return }

      // next row active, move and return
      if (this.inputFields[this.currentField + 2].disabled === false) {
        this.currentField += 2
        this.inputFields[this.currentField].focus()
        return
      }

      // next row disabled or permission blocked, loop until active row or end of list.
      // if end of list, return without changing currentField
      let tmp = this.currentField + 2
      if (this.inputFields[tmp] && this.inputFields[tmp].disabled === true) {
        while (this.inputFields[tmp] && this.inputFields[tmp].disabled === true) {
          tmp += 2
          if (!this.inputFields[tmp]) { return } // couldn't find active row before end of list, return without changing currentField
          else if (this.inputFields[tmp] && this.inputFields[tmp].disabled === false) { this.currentField = tmp; break }
        }
      }
      this.inputFields[this.currentField].focus()
    },
    turnOffLoadingScreen () {
      document.querySelectorAll('.loading-screen').forEach(el => {
        el.style.display = 'none'
      })
    },
    getShadeName (product, child) {
      if (child.shade) {
        return product.configurable_options[0].values.find(opt => Number(opt.value_index) === child.shade).label
      } else if (child.volume) {
        return product.configurable_options[0].values.find(opt => Number(opt.value_index) === child.volume).label
      } else {
        return 'No Shade Name'
      }
    },
    tabClick (brandId) {
      this.currentBrand = brandId
    },
    moneyInputChangeHandler (product, qty) {
      // validate qty
      let validatedQty = qty > 0 ? this.getNearestAllowedQuantity(product, qty) : 0

      // update gridcart summary (includes points earned calc)
      // vuex handles saving order to remote server and also updating totals
      this.$store.dispatch('quickorder/addToOrder', {sku: product.sku, moneyQuantity: validatedQty})
      this.updateTotals()
    },
    pointsInputChangeHandler (product, qty) {
      let validatedQty = qty > 0 ? this.getNearestAllowedQuantity(product, qty) : 0
      this.$store.dispatch('quickorder/addToOrder', {sku: product.sku, pointsQuantity: validatedQty})
      this.updateTotals()
    },
    getNearestAllowedQuantity (product, inputQty) {
      // rounds down to nearest allowed quantity, considering min/max purchase limits and purchase increment requirements
      // rounds up if input below required increment
      // returns Number
      let nearestQty = inputQty

      // increments, if applicable
      if (product.stock.enable_qty_increments) {
        nearestQty = inputQty - (inputQty % product.stock.qty_increments)
        if (nearestQty < product.stock.qty_increments) { nearestQty = product.stock.qty_increments }
      }
      // min/max sale caps
      if (nearestQty < product.stock.min_sale_qty) {
        nearestQty = product.stock.min_sale_qty
      } else if (nearestQty > product.stock.max_sale_qty) {
        nearestQty = product.stock.max_sale_qty
      }

      // round down to max qty if over
      if (nearestQty > product.stock.qty) {
        nearestQty = product.stock.qty - (product.stock.qty % product.stock.qty_increments)
      }

      return nearestQty
    },
    updateTotals () {
      this.$store.dispatch('quickorder/updateTotals')
      let moneyTotals = this.getMoneyTotal
      let pointsTotals = this.getPointsTotal
      if (this.useDetailedSummary) {
        Object.keys(this.orderSummary).forEach(brandId => {
          this.orderSummary[brandId] = moneyTotals[brandId]
        })
      }
      this.orderMoneyTotal = Object.values(moneyTotals).reduce((prev, curr) => prev + curr, 0)
      this.orderPointsTotal = Object.values(pointsTotals).reduce((prev, curr) => prev + curr, 0)
    },
    setLoadingScreen(state, msg = 'Adding items to cart...') {
      this.cartLoadingMessage = msg
      this.cartAddLoadingScreen = state
    },
    // productQtyArray: Array [product object, combined money and points quantity]
    // checks stock for single item
    async singleStockCheck (product) {
      const totalQty = this.currentOrderData[product.sku].mq + this.currentOrderData[product.sku].pq
      const stockResult = await this.$store.dispatch('stock/check', { product: product, qty: totalQty || 1 }, {root: true})

      // used to get the online check result
      product.onlineStockCheckid = stockResult.onlineCheckTaskId

      // check against min qty
      const calculatedQty = ((stockResult || {}).qty || 0) - (totalQty || 1)
      const isInStock = (calculatedQty > 0 && calculatedQty > (stockResult.min_qty || (product.stock || {}).min_qty))
      if (stockResult.status === 'out_of_stock' || !isInStock) {
        console.log("insufficient stock: ", product.name)
        this.stockFailSkus[product.sku] = 'Insufficient stock, please lower the quantity.'
      } else {
        this.stockPassSkus[product.sku] = true
      }
      this.$forceUpdate()
    },
    async preCartStockCheck (productsToAdd) {
      let errors = 0
      this.stockFailSkus = {}
      this.stockPassSkus = {}
      return await Promise.all(productsToAdd.map(async (product) => {
        const stockResult = await this.$store.dispatch('stock/check', { product: product[0], qty: product[1] + product[2] || 1 }, {root: true})

        // used to get the online check result
        product.onlineStockCheckid = stockResult.onlineCheckTaskId

        // check against min qty
        const calculatedQty = ((stockResult || {}).qty || 0) - ((product[1] + product[2])|| 1)
        const isInStock = (calculatedQty > 0 && calculatedQty > (stockResult.min_qty || (product[0].stock || {}).min_qty))

        if (stockResult.status === 'out_of_stock' || !isInStock) {
          errors++
          this.stockFailSkus[product[0].sku] = 'Insufficient stock, please lower the quantity.'
        } else {
          this.stockPassSkus[product[0].sku] = true
        }
      })).then( async () => {
        if (errors > 0) {
          throw new Error(`Failed stock check`)
        }
        this.cartAddLoadingScreen = false
        return true
      }).catch((err) => {
        console.error(err)
        this.cartAddLoadingScreen = false
        return false
      })
    },
    async addToRealCart () {

      // loyalty balance check
      if (this.remainingPoints < 0) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Not enough points for the selected loyalty items.'),
          action1: { label: i18n.t('OK') }
        }, {root: true})
        return
      }

      // assemble products for stock check and cart add
      const productsToAdd = []
      for (const [k, v] of Object.entries(this.currentOrderData)) {
        if ((v.mq + v.pq) > 0) {
          let p = undefined
          if (!this.productList.hasOwnProperty(k)) {
            p = this.childProducts[k]
          } else {
            p = this.productList[k]
          }
          productsToAdd.push([p, v.mq, v.pq])
        }
      }

      // pre-add stock check
      this.setLoadingScreen(true, 'Checking stock...')
      const stockCheckPassed = await this.preCartStockCheck(productsToAdd)
      if (stockCheckPassed === false) {
        let msg = 'Some of the items you\'ve selected don\'t have the requested stock:'
        msg += '\n'
        let listOfSkus = Object.keys(this.stockFailSkus)
        if (listOfSkus && listOfSkus.length) {
          for (let i = 0; i < listOfSkus.length; i++) {
            let thisProduct = undefined
            if (!this.productList[listOfSkus[i]]) { thisProduct = this.childProducts[listOfSkus[i]] }
            else { thisProduct = this.productList[listOfSkus[i]]}
            if (!thisProduct) { console.error(`can't find sku for stock check: ${listOfSkus[i]}`); continue } // something went wrong, sku missing from list
            msg += thisProduct.name
            msg += '\n'
            if (i >= 2) {
              break
            }
          }
          msg += `...and ${listOfSkus.length - 3} more`
        }

        this.setLoadingScreen(false)
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t(msg),
          action1: { label: i18n.t('OK') }
        }, {root: true})
        return
      }

      // confirmation dialog about clearing cart
      let confirmAdd = false
      if (stockCheckPassed) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'hidden',
          message: i18n.t('stock check success')
        }, {root: true})
        confirmAdd = await this.$refs.addToCartConfirm.open({
          messageText: 'This will clear your current cart before adding selected items. Proceed?',
          confirmText: 'OK',
          rejectText: 'Cancel'
        })
      }

      if (confirmAdd) {
        await this.$store.dispatch('cart/clearCart')
        this.setLoadingScreen(true, 'Adding selections to cart...')

        await this.$store.dispatch('cart/bulkAddItems', {productsToAdd})
        this.setLoadingScreen(false)
        this.setSuccessAttribute()
        await this.$store.dispatch('quickorder/setSource')
        this.$router.push(this.localizedRoute('/cart'))
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.noPermissionCopy {
  display: inline;
  margin: auto;
  padding-left: 50px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 25px;
  width: 60%;
}
input.permissionBlocked {
  background: linear-gradient(to top left,rgba(0,0,0,0) calc(50% - 1px), rgba(0,0,0,1) 50%, rgba(0,0,0,0) calc(50% + 1px), rgba(0,0,0,0) 100%);
  pointer-events: none;
}

.clearOrderButton {
  border: 2px solid black;
  padding: 5px 10px;
  display: inline;
  margin-right: 17px;
}

// this targets the notification banner for OOS messages
.notification .message {
  white-space: pre;
  line-height: 1.8;
}

input.bulkInputField.qtyError {
  border-color: red;
}
input.bulkInputField.qtyError::before {
  content: "ICON HERE";
  color: red;
}
input.bulkInputField.qtySuccess::before {
  content: "ICON HERE";
  color: green;
}

.cart-loading {
  width: 100vw;
  height: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);

  .cart-loading-container {
    position: absolute;
    top: 25vh;
    left: 50%;
    transform: translate(-50%);
    background-color: rgba(255,255,255,0.7);
    padding: 50px;
    display: flex;
    flex-direction: column;

    svg {
      width: 75px;
      height: 75px;
      margin: 30px auto 0;
    }
  }
}

@media print {
  .grid-container > div {
    break-inside: avoid;
  }

  .floating-cart-summary,
  .site-header,
  .site-header__container,
  .main-footer,
  .drawer,
  .tab-container {
    display: none !important;
  }

  .clearOrderButton {
    display: none !important;
  }

  .print-container {
    margin-left: 2rem !important;
  }

  .brandTab {
    display: none;

    &.active {
      display: inline-block;
    }
  }

  .bulkInputField {
    height: 35px;
  }
}

.container-override {
  max-width: 1250px;
}
article:last-of-type {
  padding-bottom: 100px;
}
.grid-header {
  grid-column-start: 1;
  grid-column-end: 10;
}
.grid-container {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 10px 150px 150px;
  column-gap: initial;
  row-gap: 5px;
  overflow-x: scroll;
}
.grid-1 {
  grid-column: 1;
  @apply w-5/6;
}
.grid-2 {
  grid-column: 2;
  @apply w-5/6;
  margin-left: -20px;
}
.grid-3 {
  grid-column: 3;
  @apply w-5/6 pl-16;
}
.grid-4 {
  grid-column: 4;
  display: flex;
}
.grid-5 {
  grid-column: 5;
}
.grid-6 {
  grid-column: 6;
}
.header {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}
.grid-5.header, .grid-6.header {
  text-align: center;
}
input {
  border: 1px solid black;

  &[data-qty-points] {
    outline: 2px solid #8f7b4c;
  }

  &.bulkInputField {
    @apply text-dark py-1 px-5 text-center border border-solid border-dark text-average leading-sm tracking-xs w-5/6 mb-1 mx-auto;
  }
}
.tab-container {
  display: flex;
  padding: 20px 0;
}
.brandTab {
  margin: 5px 0;
  padding: 5px 10px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.brandTab:first-of-type {
  border-left: 2px solid black;
}
.brandTab:last-of-type {
  border-right: 2px solid black;
}
.active {
  background-color: black;
  color: white;
}
.floating-cart-summary {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 500;
  border: 2px solid black;
  width: 290px;
  padding: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.floating-cart-summary > div {
  display: flex;
}
.floating-cart-summary .text {
  margin-right: auto;
}
.floating-cart-summary .addToCart {
  background-color: black;
  color: white;
  padding: 5px;
  margin-top: 10px;
}
article h2 {
  margin: 20px 0 10px;
}
.grid-container button {
  padding: 0 5px;
  border: 1px solid black;
  margin: auto;
  width: 50%;
}
input:disabled {
  color: grey;
  background-color: #cdcdcd;
}
div.out-of-stock{
  color: grey;
  text-decoration: line-through;
}
div.category {
  padding-bottom: 200px;
}
/* loading screen and progress bar */
.loading-screen {
  display: flex;
  flex-direction: column;
}
.loading-screen h2 {
  margin: 50px auto 10px;
}
.loading-container {
  width: 500px;
  margin: 20px auto;
}
.loading-bar {
  width: 100%;
  background-color: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1 3px rgba(0, 0, 0, 0.2);
}
#loading-bar-fill {
  display: block;
  width: 0;
  height: 22px;
  background-color: #393939;
  border-radius: 3px;
  //transition: width 0.1s ease-in-out;
  animation: 6s ease-in-out 0s loadingBarFill;
}
@keyframes loadingBarFill {
  from {
    width: 0;
  }
  to {
    width: 93%;
  }
}
/* vue transition classes */
.v-enter-active {
  transition: opacity 0.5s ease, top 0.5s ease;
}
.v-enter-from {
  opacity: 0;
}
.v-enter-to {
  opacity: 1
}
.v-leave-to {
  opacity: 0;
}
</style>
